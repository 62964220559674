export default {
  HEADER_TITLE_MAX_LENGTH: 150,
  HEX_INPUT_MAX_LENGTH: 7,
  SHORTCUT_MAX_LENGTH: 20,
  WEBSITE_SETTINGS: {
    isActivated: true,
    header: {
      color: '#fff',
      gradientEnd: '#1f60f2',
      gradientStart: '#17a3d8'
    },
    bubbles: {
      background: '#1d76ea',
      color: '#fff'
    },
    voiceOptions: {
      isAudioRecordingVisible: true,
      isAudioMobileFront: true,
      isAudioDesktopFront: false,
      isVoiceFirstUI: false
    },
    uploadSettings: {
      isActivated: false
    },
    chatBubble: {
      isDefaultStyle: true,
      bubbleText: ''
    },
    customCSS: '',
    shortcutMenu: [],
    maxConversationInactivityDuration: 10 // 10 mins
  },
  YELDA_PROD_BASE_URL: 'https://app.yelda.ai', // To assistantUrl webchat setup
  YELDA_HOSTING_BASE_URL: 'https://web.yelda.ai', // For the webchat yelda hosted url
  PLATFORMS: {
    WEBCHAT: {
      /**
       * Ref: https://github.com/CSSLint/csslint/wiki/Rules
       */
      CSS_RULES: {
        'adjoining-classes': 0,
        // box-model set to 1 gives warning when padding used with width/height, so it is disabled
        'box-model': 0,
        'box-sizing': 0,
        'bulletproof-font-face': 0,
        'compatible-vendor-prefixes': 0,
        'display-property-grouping': 0,
        'duplicate-background-images': 1,
        'duplicate-properties': 1,
        'empty-rules': 1,
        'fallback-colors': 0,
        floats: 0,
        'font-faces': 0,
        'font-sizes': 0,
        gradients: 0,
        // To allow @import this import validation is disabled
        import: 0,
        important: 0,
        'known-properties': 1,
        'overqualified-elements': 0,
        'qualified-headings': 1,
        'regex-selectors': 1,
        shorthand: 0,
        'star-property-hack': 1,
        'text-indent': 1,
        'underscore-property-hack': 1,
        'unique-headings': 1,
        'universal-selector': 1,
        'unqualified-attributes': 1,
        'vendor-prefix': 0,
        'zero-units': 0
      }
    },
    PHONE: {
      DEFAULT_COUNTRY_FILTER_VALUE: 'all',
      TABS: {
        PHONE: 'phone',
        TRANSFER: 'transfer',
        POST_CALL_ACTIONS: 'post_call_actions',
        BLOCKED_NUMBERS: 'blocked_numbers',
        CALL_INTERCEPTION: 'call_interception'
      },
      DEFAULT_INITIAL_DELAY: 50,
      MAX_INITIAL_DELAY: 5000,
      // Ref for length and valid characters regex:
      // https://support.twilio.com/hc/en-us/articles/223133867-Using-Alphanumeric-Sender-ID-with-Messaging-Services
      SENDER_ID_MAX_LENGTH: 11,
      SENDER_ID_REGEX: /^(?=.*[a-zA-Z])([a-zA-Z0-9 +-_&]{1,11})$/,
      BLOCKED_PHONE_NUMBER_REASONS: {
        ADDED_MANUALLY: 'ADDED_MANUALLY',
        TOO_MANY_CALLS: 'TOO_MANY_CALLS'
      },
      STOP_LISTENING_MESSAGES_STATUSES: {
        NEVER: 'never',
        ALWAYS: 'always'
      },
      WAITING_MESSAGES_STATUSES: {
        NEVER: 'never', // no waiting message
        ALWAYS: 'always', // 1 waiting message each gen ai
        ALWAYS_LOOP: 'always_loop', //  waiting message for each gen ai, in loop until the gen ai is ready
        ONLY_FIRST_TIME: 'only_first_time' // waiting message only for first gen ai answer of the call
      },
      WAITING_MANAGER_TYPES: {
        MESSAGE: 'waiting_message',
        SOUND: 'waiting_sound',
        STOP_LISTENING_MESSAGE: 'stop_listening_message'
      },
      MAX_WAITING_MUSIC_FILE_SIZE: 10485760, // 10 Mo
      ACCEPTED_EXTENSIONS: ['wav'],
      ACCEPTED_CONTENT_TYPES_STRING: 'audio/wav',
      WAITING_MUSIC_BASE_DIRECTORY: 'twilio/waiting',
      PHONE_NUMBER_DIRECTORY_REGEX: /[+\s-]/g,
      CALL_INTERCEPTION_INTERACTION: {
        MIN: 1,
        MAX: 100
      }
    },
    WHATSAPP: {
      NAME: 'whatsapp'
    }
  },
  DEMO_PAGE_SAMPLE_QUESTIONS_SEPARATOR: ';'
}
